import React from 'react'
import './_footer.scss'

const Footer = (props) => (
    <div className='site-footer'>
         <h4 className='text-center pt-3'>Fourhillside
        { /* <Link to="/"> <img src={logo} alt={props.siteTitle} className="footerLogo"/></Link> */}
        </h4>
        <div className='footer-social-links'>
            <ul classsName='social-links-list'>
                <li><a href="mailto:andrew@wghproperty.co.uk">
                <i class="fas fa-envelope fa-2x"></i>
                </a></li>
                <li><a href="tel:+441179221222">
                <i class="fas fa-phone fa-2x"></i>
                </a></li>
            </ul>
                <p>© {new Date().getFullYear()} All Rights Reserved</p>
        </div>
    </div>
)
 
export default Footer