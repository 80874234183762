import React from "react"
import { Link } from "gatsby"
import wghLogo from '../../images/wgh-logo.gif'

import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink } from 'reactstrap';

 class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.toggle = this.toggle.bind(this);
    this.links = this.links.bind(this);
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  links() {
    return [{
      'link': '/',
      'linkText': 'Home'
    },{
      'link': '/lower-ground-floor',
      'linkText': 'L/G Floor'
    },{
      'link': '/ground-floor',
      'linkText': 'Ground Floor'
    },{
      'link': '/first-floor',
      'linkText': 'First'
    },{
      'link': '/second-floor',
      'linkText': 'Second'
    },{
      'link': '/contact',
      'linkText': 'Contact'
    }]
  }
  render() {
    return (
      <div>
        <Navbar light expand="sm">
          <div className="container">
            <div className="col-md-3 pull-left site-name">
              <Link to="/">
                <div className="col-xs-12">
                  Fourhillside
                </div>
                <div className="col-xs-12">
                  <img src={wghLogo} alt={'4 Hillside Enquiries'} className="siteLogo"/>
                </div>
              </Link>
            </div>
            <div className="pt-10 col-md-9 pull-right">
              <div className="header-contacts">
                <div className="pt-10 pull-right">
                  <a href="mailto:andrew@wghproperty.co.uk">
                      <span class="fa fa-envelope fa-lg"/>
                  </a>
                  <a href="tel:+441179221222">
                      <span class="fa fa-phone fa-lg"/>
                  </a>
                </div>
              </div>
              <NavbarToggler onClick={this.toggle} />
              <div className="mainMenu">
                <Collapse isOpen={this.state.isOpen} navbar>
                  <Nav className="ml-auto" navbar>
                    {this.links().map(({link, linkText}, index) =>(
                      <NavItem>
                        <NavLink>
                          <Link activeClassName="active-header" to={link}>{linkText}</Link>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </Collapse>
              </div>
            </div>
          </div>
        </Navbar>
      </div>
    );
  }
}

export default Header
