/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../header/header"
import Footer from "../footer/footer"
import "../../styles/index.scss"
import { Col } from 'reactstrap'
import Jumbotron from '../jumbotron/jumbotron'

const Layout = ({ children, isHomepage, pageTitle }) => { //props.pageTitle
  // const sideBar = true
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className="fullHeight">
      <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.3/css/all.css" crossOrigin="anonymous" />
      <Header siteTitle={data.site.siteMetadata.title} />
      {isHomepage && (<Jumbotron/>)}
      <div className={isHomepage ? "container pt-4" : "container pt-4 pb-5"} id="content">
        <Col xs={12}>
          { pageTitle && (<h1>{pageTitle}</h1>)}        
          <div>{children}</div>
        </Col>
      </div>
      <Footer siteTitle={data.site.siteMetadata.title} />
    </div>
  )
}

export default Layout