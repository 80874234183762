import React from 'react';
import { Jumbotron as Jumbo, Container, Button } from 'reactstrap';
import "./_jumbotron.scss"
import { Link } from 'gatsby'

const Jumbotron = () => (

    <Jumbo fluid className="jumbo">
      <div className="overlay"></div>
      <Container>
        <h1 className="text-center">Refurbished period office to let on flexible terms</h1>
        <div class="text-center">
         {/* <BannerButton /> */}
        </div>      
      </Container>
    </Jumbo>
  
)

const BannerButton = () => (
    <Link to="/trial">
      <Button className="button" color="primary" size="lg">Book your lesson today!</Button>
    </Link>
)

export default Jumbotron
